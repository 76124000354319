import { Category, Product } from '@shopware-pwa/types'

const SHOP_PATH = 'shop'
const ACCOUNT_PATH = 'account'
const ORDERS_PATH = 'orders'
const CHECKOUT_PATH = 'checkout'

export const getShopPermalink = () => `/${SHOP_PATH}`

export const getProductPermalink = (product: Product) => {
  if (!product.seoUrls || !product.seoUrls.length) {
    return `/${SHOP_PATH}/detail/${product.id}`
  }
  return product.seoUrls[0].seoPathInfo ? `/${product.seoUrls[0].seoPathInfo}` : `${product.seoUrls[0].pathInfo}`
}

export const getCategoryPermalink = (category: Category) => {
  if (!category.seoUrls || !category.seoUrls.length) {
    return ''
  }
  return category.seoUrls[0].seoPathInfo ? `/${category.seoUrls[0].seoPathInfo}` : `${category.seoUrls[0].pathInfo}`
}

export const getCategoryPermalinkByForeignKey = (foreignKey: string) => {
  return `/${SHOP_PATH}/navigation/${foreignKey}`
}

export const getCartPermalink = () => {
  return `/${SHOP_PATH}/cart`
}

export const getCheckoutPermalink = () => {
  return `/${SHOP_PATH}/${CHECKOUT_PATH}`
}

export const getThankYouPermalink = (orderId: string) => {
  return `/${SHOP_PATH}/${CHECKOUT_PATH}/order/${orderId}`
}

export const getLoginPermalink = () => {
  return `/${SHOP_PATH}/login`
}

export const getRegisterPermalink = () => {
  return `/${SHOP_PATH}/register`
}

export const getSearchPermalink = () => {
  return `/${SHOP_PATH}/search`
}

export const getAccountPermalink = () => {
  return `/${SHOP_PATH}/${ACCOUNT_PATH}`
}

export const getProfilePermalink = () => {
  return `/${SHOP_PATH}/${ACCOUNT_PATH}/profile`
}

export const getAddressesPermalink = () => {
  return `/${SHOP_PATH}/${ACCOUNT_PATH}/addresses`
}

export const getPaymentPermalink = () => {
  return `/${SHOP_PATH}/${ACCOUNT_PATH}/payment`
}

export const getOrdersPermalink = () => {
  return `/${SHOP_PATH}/${ACCOUNT_PATH}/${ORDERS_PATH}`
}

export const getOrderPermalink = (orderId: string) => {
  return `/${SHOP_PATH}/${ACCOUNT_PATH}/${ORDERS_PATH}/${orderId}`
}

export const getPasswordRecoveryPermalink = () => {
  return `/${SHOP_PATH}/${ACCOUNT_PATH}/recovery`
}

export const getTermsAndConditionsPermalink = () => {
  return `/`
}

export const getDataProtectionPermalink = () => {
  return `/`
}
